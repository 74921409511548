import React, { Component } from "react";
import { Helmet } from "react-helmet";

class CookiePolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow" />
          <title>Lorebea s.r.l. | Cookie Policy </title>
          <meta name="description" content="Cookie Policy di Lorebea s.r.l." />
          <meta name="url" content="https://lorebea.cloud/cookie-policy" />
        </Helmet>
        <div className="pages policy">
          <h2>INFORMATIVA ESTESA SULL'USO DEI COOKIE</h2>
          <hr style={{ backgroundColor: "#6d6d6d" }} />
          <h2>Uso dei cookie</h2>
          <p>
            Questo sito internet utilizza i cosiddetti cookie per garantire il
            corretto funzionamento delle procedure e per offrire agli utenti una
            migliore esperienza d'uso.
            <br />
            Il presente documento fornisce informazioni sui cookie, su come sono
            utilizzati dal sito e su come gestirli.
          </p>
          <h2>Definizioni</h2>
          <p>
            I cookie sono porzioni di testo di piccole dimensioni registrate dal
            browser sul dispositivo del visitatore e permettono al sito internet
            di memorizzare informazioni da riutilizzare nel corso della
            navigazione (cookie di sessione) oppure anche nel caso di visite
            successive (cookie persistenti):
          </p>
          <ul>
            <li>
              i <strong>cookie di sessione</strong> vengono eliminati
              automaticamente alla chiusura del browser;
            </li>
            <li>
              i <strong>cookie persistenti</strong>, se non eliminati di propo
              sito, rimangono fino a una data preimpostata.
            </li>
          </ul>
          <p>
            I cookie vengono memorizzati in base alle preferenze dell'utente dal
            browser sul dispositivo in uso, come ad esempio: il computer, lo
            smartphone o il tablet.
          </p>
          <h2>Tipologie di cookie</h2>
          <p>
            Esistono diverse tipologie di cookie ognuna delle quali è destinata
            a scopi diversi.
          </p>
          <h3>Cookie tecnici</h3>
          <p>
            I cookie tecnici{" "}
            <strong>
              sono quelli indispensabili per garantire il corretto funzionamento
              del sito
            </strong>
            in relazione ai servizi offerti. Verranno sempre inviati a meno che
            il visitatore non modifichi le impostazioni del proprio browser, in
            questo caso le pagine del sito potrebbero venire penalizzate nella
            visualizzazione e nelle funzionalità.
            <br />A titolo di esempio, i cookie tecnici possono essere
            utilizzati per:
          </p>
          <ul>
            <li>
              gestire e ricordare, dove previsto, alcune preferenze di
              visualizzazione come ad esempio la lingua;
            </li>
            <li>
              permettere agli utenti registrati di accedere ad aree sicure del
              sito senza dover continuamente effettuare il login;
            </li>
            <li>
              gestire e ricordare gli elementi aggiunti al carrello in un
              negozio on line.
            </li>
          </ul>
          <h3>Cookie analitici</h3>
          <p>
            <strong>Questi cookie, </strong> detti anche &quot;Performance
            cookie&quot;, <strong>sono assimilati ai cookie tecnici</strong> e
            sono utilizzati per effettuare{" "}
            <strong>l'analisi e il monitoraggio del traffico</strong> sul sito.
            Vengono utilizzati&nbsp; con lo scopo di{" "}
            <strong>raccogliere informazioni statistiche anonime</strong>
            sull'uso del sito come ad esempio: le pagine visitate, il tempo di
            permanenza sul sito, il flusso di pagine visitate, la provenienza
            geografica ecc.
          </p>
          <p>
            Nel caso specifico dei cookie di <strong>Google Analytics</strong>,
            è possibile disattivarne la ricezione installando il componente
            aggiuntivo per il proprio browser disponibile cliccando sul link
            sottostante.
          </p>
          <ul>
            <li>
              <a href="https://tools.google.com/dlpage/gaoptout">
                Componente aggiuntivo del browser per la disattivazione di
                Google Analytics
              </a>
            </li>
          </ul>
          <h3>Cookie di terze parti senza controllo diretto</h3>
          <p>
            Questo tipo di cookie viene utilizzato di solito nel caso in cui il
            sito integri funzionalità fornite da siti esterni. Solo per citare
            alcuni casi, i cookie di terze parti potrebbero essere registrati
            da:
          </p>
          <ul>
            <li>video YouTube, Vimeo o altri provider di servizi video;</li>
            <li>mappe geografiche (Google Maps, Bing Maps ecc...);</li>
            <li>
              strumenti di condivisione sui social network forniti da Facebook,
              Twitter o altri.
            </li>
          </ul>
          <h3>Cookie di profilazione</h3>
          <p>
            Si tratta di cookie permanenti utilizzati per identificare, in modo
            anonimo e non, le preferenze dell'utente per diversi scopi come ad
            esempio: l'ottimizzazione dell'esperienza d'uso oppure per fini
            commerciali e di marketing.
            <br />
            Considerata la particolare invasività che i cookie di profilazione
            (soprattutto quelli terze parti) possono avere nell'ambito della
            sfera privata degli utenti, la normativa europea e italiana
            prevedono che l'utente debba essere adeguatamente informato sull'uso
            degli stessi ed esprimere il proprio valido consenso all'inserimento
            dei cookie sul proprio dispositivo.
          </p>
          <h2>Cookie utilizzati da questo sito</h2>
          <p>Questo sito utilizza le seguenti tipologie di cookie:</p>
          <ul>
            <li>
              <strong>cookie tecnici</strong>;
            </li>
            <li>
              <strong>cookie analitici</strong> Google Analytics.
              <br />
              Questo sito non utilizza e non consente a terzi di utilizzare gli
              strumenti di analisi per monitorare o per raccogliere informazioni
              personali di identificazione.
            </li>
            <li>
              Potrebbero essere utilizzati{" "}
              <strong>cookie di terze parti.</strong>
              <br />
              Il sito non controlla la registrazione o l'accesso a questi
              cookie. Per ricevere ulteriori informazioni sull'utilizzo dei
              cookie da parte di terzi, consultare l'informativa sulla privacy e
              sui cookie dei servizi in questione.
            </li>
          </ul>
          <h2>Consenso per l'uso dei cookie</h2>
          <p>
            <strong>Questo sito NON UTILIZZA COOKIE DI PROFILAZIONE</strong>,
            pertanto secondo la normativa vigente non è tenuto a chiedere
            consenso per i cookie tecnici e di analisi in quanto necessari a
            fornire i servizi proposti.
          </p>
          <h2>Gestione dei cookie</h2>
          <p>
            L'utente può decidere se accettare o meno i cookie utilizzando le
            impostazioni del proprio browser. L'impostazione può essere definita
            in modo specifico per i diversi siti e applicazioni web, inoltre i
            browser di ultima generazione consentono di definire impostazioni
            diverse per i cookie registrati direttamente dal sito e per quelli
            di terze parti.
          </p>
          <p>
            <strong>Attenzione:</strong> la disabilitazione totale o parziale
            dei cookie può compromettere l'utilizzo delle funzionalità del sito.
          </p>
          <h3>Guide per la gestione dei cookie sui browser più diffusi</h3>
          <ul>
            <li>
              <a href="https://support.google.com/chrome/answer/95647?hl=it">
                Google Chrome
              </a>
            </li>
            <li>
              <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">
                Microsoft Internet Explorer
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/it-it/HT201265">
                Apple Safari
              </a>
            </li>
            <li>
              <a href="http://help.opera.com/Windows/10.00/it/cookies.html">
                Opera Software Opera
              </a>
            </li>
          </ul>
          <p>Links aggiornati al mese di settembre 2019</p>
        </div>
      </React.Fragment>
    );
  }
}

export default CookiePolicy;
