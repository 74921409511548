import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow" />
          <title>Lorebea s.r.l. | Privacy Policy </title>
          <meta name="description" content="Privacy Policy di Lorebea s.r.l." />
          <meta name="url" content="https://lorebea.cloud/privacy-policy" />
        </Helmet>
        <div className="pages policy">
          <h1>Privacy Policy</h1>
          <br />
          <h4>
            IL PRESENTE DOCUMENTO COSTITUISCE LA PRIVACY POLICY DI QUESTO SITO
            WEB
          </h4>
          <hr style={{ backgroundColor: "#6d6d6d" }} />
          <p>
            Gentile visitatore,
            <br />
            Ti invitiamo a leggere con attenzione la presente Privacy Policy in
            quanto contiene informazioni importanti riguardo alla tutela dei
            dati personali e alle misure di sicurezza che sono state adottate
            per garantirne la protezione e la riservatezza in base a quanto
            stabilito dal Regolamento UE 679/2016 in materia di protezione dei
            dati personali.
            <br />
            La presente Privacy Policy si intende quale informativa ai sensi
            dell'art. 13 del Regolamento UE 679/2016 ed è resa solo per il sito
            www.lorebea.cloud e non si applica ad altri siti web raggiungibili
            tramite link.
          </p>
          <h2>Principi</h2>
          <p>
            Il trattamento dei tuoi dati è improntato a principi di liceità,
            trasparenza, correttezza, limitazione delle finalità e della
            conservazione, minimizzazione, esattezza, integrità dei dati e
            riservatezza.
          </p>
          <h2>Titolare del trattamento</h2>
          <p>
            Titolare del trattamento è Lorebea srl , Via Landini Remo, 4/D -
            51010 Massa e Cozzile (Pistoia), email: info@lorebea.it
          </p>
          <h2>Le finalità</h2>
          <p>
            Le informazioni personali di cui Lorebea srl viene a conoscenza
            attraverso questo sito riguardano i dati di navigazione come
            indirizzo IP, nomi di dominio, ecc. non associati direttamente al
            visitatore del sito e la trasmissione volontaria di dati all'interno
            di messaggi di posta elettronica. Le informazioni derivanti da tali
            messaggi sono utilizzate nel rispetto della normativa vigente, al
            fine di rispondere al richiedente.
            <br />
            L'invio di posta elettronica agli indirizzi indicati su questo sito
            comporta la successiva acquisizione dell'indirizzo del mittente,
            necessario per rispondere alle richieste, nonché degli eventuali
            altri dati personali contenuti nel messaggio. L'utente è libero di
            fornire i dati personali per la richiesta di informazioni o altre
            comunicazioni.
          </p>
          <h2>Base giuridica del trattamento</h2>
          <p>
            La base legale su cui si fonda il trattamento dei dati raccolti
            attraverso il sito web è da considerarsi il legittimo interesse del
            Titolare del trattamento, indicato nel normale rapporto tra
            visitatore richiedente informazioni e il Titolare del trattamento.
          </p>

          <h2>Le modalità</h2>
          <p>
            I dati personali sono trattati con strumenti elettronici e in forma
            cartacea ed utilizzati per il fine richiesto dall'interessato.
            Specifiche misure di sicurezza sono poste in atto per prevenire la
            perdita dei dati, usi illeciti o non corretti ed accessi non
            autorizzati.
          </p>
          <h2>Cookies</h2>
          <p>
            I<i>cookies</i> sono piccoli file di testo scaricati sul dispositivo
            (computer, tablet, smartphone, ecc.) attraverso il quale l'utente
            visita un sito web. Alcuni di questi, i cosiddetti{" "}
            <i>cookies tecnici</i>, servono a facilitare la navigazione o a
            fornire un servizio richiesto dall'utente. Senza il ricorso a tali
            cookies alcune operazioni non potrebbero essere compiute o sarebbero
            più difficili o meno sicure. Altri tipi di cookies invece, i c.d{" "}
            <i>cookies di profilazione</i>, hanno lo scopo di tracciare la
            navigazione dell'utente sul web e stilare un profilo dei suoi gusti,
            delle sue abitudini, delle sue scelte, ecc. al fine di marketing
            mirato e pubblicità personalizzata.
            <br />
            Informazioni dettagliate sull’utilizzo di cookies sono disponibili
            nella{" "}
            <a href="https://www.lorebea.cloud/cookie-policy">
              {" "}
              cookie policy{" "}
            </a>{" "}
            del sito.
          </p>
          <h2>Comunicazione e diffusione dei dati</h2>
          <p>
            I dati non saranno oggetto di alcuna diffusione, vendita o scambio.
            Essi potranno essere oggetto di condivisione con soggetti che
            erogano servizi (ad esempio ricezione e invio di e-mail).
          </p>
          <h2>Natura del conferimento</h2>
          <p>
            Il conferimento dei dati è facoltativo e soggetto alla volontà
            dell'interessato. Tuttavia il loro mancato conferimento potrebbe
            rendere impossibile un riscontro alla tua richiesta.
          </p>
          <h2>Trasferimenti all'estero</h2>
          <p>
            I tuoi dati non sono trasferiti a destinatari al di fuori
            dell'Unione Europea.
          </p>
          <h2>Periodo di conservazione dei dati</h2>
          <p>
            I dati acquisiti saranno conservati per il tempo strettamente
            necessario a rispondere alle tue richieste, poi saranno cancellati.
          </p>
          <h2>I tuoi diritti</h2>
          <p>
            L’interessato ha facoltà di esercitare i diritti previsti dalla
            normativa contattando il Titolare del trattamento ai recapiti
            riportati in calce. In particolare l’interessato ha diritto a:{" "}
          </p>
          <ul>
            <li>
              <u>revocare il consenso in ogni momento. </u>L’Interessato può
              revocare il consenso al trattamento dei propri dati personali
              precedentemente espresso senza pregiudicare la liceità del
              trattamento basata sul consenso prima della revoca.{" "}
            </li>
            <li>
              <u>opporsi al trattamento dei propri dati. </u>L’Interessato può
              opporsi al trattamento dei propri dati quando esso avviene su una
              base giuridica diversa dal consenso.{" "}
            </li>
            <li>
              <u>accedere ai propri dati. </u>L’Interessato ha diritto ad
              ottenere informazioni sui dati trattati dal Titolare, su
              determinati aspetti del trattamento ed a ricevere una copia dei
              dati trattati.{" "}
            </li>
            <li>
              <u>verificare e chiedere la rettificazione. </u>L’Interessato può
              verificare la correttezza dei propri dati e richiederne
              l’aggiornamento o la correzione.{" "}
            </li>
            <li>
              <u>ottenere la limitazione del trattamento. </u>Quando ricorrono
              determinate condizioni, l’Interessato può richiedere la
              limitazione del trattamento dei propri dati. In tal caso il
              Titolare non tratterà i dati per alcun altro scopo se non la loro
              conservazione.{" "}
            </li>
            <li>
              <u>
                ottenere la cancellazione o rimozione dei propri dati personali.{" "}
              </u>
              Quando ricorrono determinate condizioni, l’Interessato può
              richiedere la cancellazione dei propri dati da parte del Titolare.{" "}
            </li>
            <li>
              <u>
                ricevere i propri dati o farli trasferire ad altro titolare.{" "}
              </u>
              L’Interessato ha diritto di ricevere i propri dati in formato
              strutturato, di uso comune e leggibile da dispositivo automatico
              e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
              ostacoli ad un altro titolare. Questa disposizione è applicabile
              quando i dati sono trattati con strumenti automatizzati ed il
              trattamento è basato sul consenso dell’Interessato, su un
              contratto di cui l’Interessato è parte o su misure contrattuali ad
              esso connesse.{" "}
            </li>
            <li>
              <u>proporre reclamo. </u>L’Interessato può proporre un reclamo
              all’autorità di controllo della protezione dei dati personali
              competente o agire in sede giudiziale.{" "}
            </li>
          </ul>

          <h2>Modifiche alla Privacy Policy</h2>
          <p>
            Lorebea srl si riserva di modificare o aggiornare il contenuto della
            presente Privacy Policy anche in seguito a variazioni della
            normativa. Ti invitiamo pertanto a visitare regolarmente questa
            pagina al fine di rimanere sempre aggiornato sulle modalità di
            trattamento dei tuoi dati.
          </p>
          <h2>Le richieste</h2>
          <p>
            Le richieste inerenti le modalità, le finalità e quant'altro
            relativo al trattamento dei dati personali dovranno essere inviate
            al Titolare del trattamento al seguente contatto: <br />
            <br />
            <br />
            Lorebea srl a Socio Unico <br />
            Via Landini Remo, 4/D <br />
            51010 Massa e Cozzile (Pistoia) <br />
            email: info@lorebea.it <br />
            Tel.: 0572 74621 <br />
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
