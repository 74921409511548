import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { images, english } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow" />
          <title>
            Lorebea s.r.l. | Home | Ciabatte, Pantofole ed altro ancora
          </title>
          <meta
            name="description"
            content="Produzione e commercializzazione ciabatte personalizzate in PVC repellente all'acqua con suola anti-scivolo. Ciabatte per albergo personalizzate con suola anti-scivolo. Ciabatte per la casa per uomo e donna. Accessori per il bagno fatti in spugna o tessuti naturali."
          />
          <meta name="url" content="https://www.lorebea.cloud/home" />
        </Helmet>
        <div style={{ backgroundColor: "#000" }}>
          <div className="image-container fade-in">
            <div className="image-wrapper">
              {images.banner ? <img src={images.banner} alt="" /> : ""}
            </div>
          </div>
          <div className="row home-products fade-in">
            <div className="prod left col-lg-6 col-sm-12">
              {images.pantofole ? (
                <Link to="/prodotti/pantofole-alberghi">
                  <div className="prod-text-title">
                    <h5 className="home-prod-title text-center">
                      {english ? "HOTEL SLIPPERS" : "PANTOFOLE PER ALBERGHI"}
                    </h5>
                    <p className="home-prod-title text-center">
                      {english ? "Learn more >" : "Scopri di più >"}
                    </p>
                  </div>
                  <img src={images.pantofole} alt="" />
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="prod right col-lg-6 col-sm-12">
              {images.ciabatte ? (
                <Link to="/prodotti/ciabatte-spa">
                  <div className="prod-text-title">
                    <h5 className="home-prod-title text-center">
                      {english ? "SPA SLIPPERS" : "CIABATTE PER SPA"}
                    </h5>
                    <p className="home-prod-title text-center">
                      {english ? "Learn more >" : "Scopri di più >"}
                    </p>
                  </div>
                  <img src={images.ciabatte} alt="" />
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row home-products">
            <div className="prod bottom col-lg-12 col-sm-12">
              {images.cortesia ? (
                <Link to="/prodotti/oggettistica">
                  <div className="prod-text-title">
                    <h5 className="home-prod-title text-center">
                      {english ? "FANCY GOODS" : "OGGETTISTICA"}
                    </h5>
                    <p className="home-prod-title text-center">
                      {english ? "Learn more >" : "Scopri di più >"}
                    </p>
                  </div>
                  <img src={images.cortesia} alt="" />
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
