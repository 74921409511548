import React, { Component } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Fade from "react-reveal/Fade";

class ProductItem extends Component {
  state = {
    loading: true
  };
  displayImage = () => {
    this.setState({ loading: false });
  };
  render() {
    const { img_url, name, id, slug, pre_img_url } = this.props;

    const { loading } = this.state;
    return (
      <Link
        to={{
          pathname: `/prodotti/${slug}/${id}`,
          slug: slug
        }}
        className="col-lg-3 col-6 fade-in"
      >
        <LazyLoad height={200} once>
          <Fade>
            <img
              src={loading ? pre_img_url : img_url}
              alt={name}
              onLoad={this.displayImage}
            />
            <p className="text-center m-3">{loading ? "" : name}</p>
          </Fade>
        </LazyLoad>
      </Link>
    );
  }
}

export default ProductItem;
