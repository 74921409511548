import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    const { english } = this.props;
    return (
      <div className="footer">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <h6>{!english ? "Indirizzo" : "Address"}</h6>
            <p>
              <i className="fas fa-map-marker-alt mr-2" /> Via Landini Remo, 4d
            </p>
            <p>51010 Massa e Cozzile (PT)</p>
          </div>
          <div className="col-lg-3 col-sm-6">
            <h6>{!english ? "Contatti" : "Contacts"}</h6>
            <p>
              <i className="fas fa-phone-alt mr-2" /> +39 0572 74621
            </p>
            <p>
              <i className="fas fa-fax mr-2" /> fax: +39 0572 390092
            </p>
            <p>
              <i className="fas fa-envelope mr-2" /> info@lorebea.it
            </p>
          </div>
          <div className="col-lg-3 col-sm-6">
            <h6>{!english ? "Altre Informazioni" : "Other Info"}</h6>
            <p>Lorebea Srl a Socio Unico</p>
            <p>P.IVA 01701930479</p>
            <p>Iscr. REA: PT 0172090</p>
            <p>Cap.Soc. € 60.000,00 I.V.</p>
          </div>
          <div className="col-lg-3 col-sm-6 social">
            <a href="https://www.facebook.com/lorebeaslippers/">
              <i className="fab fa-facebook-square" />
            </a>
            <a href="https://www.instagram.com/lorebea_srl/">
              <i className="fab fa-instagram" />
            </a>
            <div id="mc_embed_signup" className="signup_footer mt-2">
              <form
                action={
                  "https://gmail.us20.list-manage.com/subscribe/post?u=5208f487c96096b93fe083585&amp;id=47836e82b5"
                }
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
                style={{ padding: "0" }}
              >
                <div id="mc_embed_signup_scroll">
                  <label htmlFor="mce-EMAIL">
                    {!english
                      ? "Iscriviti alla Newsletter *"
                      : "Subscribe to our Newsletter *"}
                  </label>
                  <input
                    type="email"
                    defaultValue=""
                    name="EMAIL"
                    className="email"
                    id="mce-EMAIL"
                    placeholder="email address"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_5208f487c96096b93fe083585_47836e82b5"
                      tabIndex="-1"
                      defaultValue=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value={!english ? "Iscriviti" : "Subscribe"}
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
            {!english ? (
              <p
                style={{
                  fontSize: "8pt",
                  backgroundColor: "#0c0c0c",
                  lineHeight: "normal"
                }}
              >
                * Inserendo il tuo indirizzo e-mail, acconsenti a ricevere le
                newsletter di Lorebea relative alle nuove promozioni. Per
                maggiori informazioni consulta la nostra{" "}
                <Link to="/privacy-policy" style={{ fontSize: "8pt" }}>
                  Informativa a tutela della privacy
                </Link>
                .
              </p>
            ) : (
              <p
                style={{
                  fontSize: "8pt",
                  backgroundColor: "#0c0c0c",
                  lineHeight: "normal"
                }}
              >
                * By entering your e-mail address, you consent to receive
                Lorebea's new promotions and offers. For more informations
                consult our{" "}
                <Link to="/privacy-policy" style={{ fontSize: "8pt" }}>
                  Privacy Policy
                </Link>
                .
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className="row footer-policy">
          <ul>
            <li>
              <Link to="/cookie-policy">Cookie Policy</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
