import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductItem from "./common/productItem";
import { Helmet } from "react-helmet";

class CiabatteSpa extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { ciabatte, english } = this.props;
    return (
      <div className="pages product-items fade-in">
        <Helmet>
          <meta name="robots" content="index,follow" />
          <meta name="googlebot" content="index,follow" />
          <title>Lorebea s.r.l. | Prodotti | Ciabatte per Spa</title>
          <meta
            name="description"
            content="Produzione e commercializzazione ciabatte personalizzate in PVC repellente all'acqua con suola anti-scivolo. Ciabatte per albergo personalizzate con suola anti-scivolo. Ciabatte per la casa per uomo e donna. Accessori per il bagno fatti in spugna o tessuti naturali."
          />
          <meta name="url" content="https://lorebea.cloud/ciabatte-spa" />
        </Helmet>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page">
              <Link to="/prodotti" className="breadcrumb-item active">
                {!english ? "Prodotti" : "Products"}
              </Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link
                to="/prodotti/ciabatte-spa"
                onClick={e => e.preventDefault()}
                className="breadcrumb-item"
              >
                {!english ? "Ciabatte per Spa" : "Spa Slippers"}
              </Link>
            </li>
          </ol>
        </nav>
        <div className="row">
          {ciabatte.map(ciabatta => (
            <ProductItem
              key={ciabatta.id}
              id={ciabatta.slug}
              img_url={
                ciabatta._embedded["wp:featuredmedia"]["0"].media_details.sizes
                  .medium_large.source_url
              }
              pre_img_url={
                ciabatta._embedded["wp:featuredmedia"]["0"].media_details.sizes
                  .thumbnail.source_url
              }
              name={
                !english ? ciabatta.title.rendered : ciabatta.acf.english_title
              }
              slug="ciabatte-spa"
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CiabatteSpa;
