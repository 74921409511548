import React from "react";

const siteLogo = require("../../images/site-logo.svg");

const Loader = () => {
  return (
    <div className="spinner-logo text-center ">
      <div className="logo-pulse pulse">
        <img
          className="site-logo"
          src={siteLogo}
          width="200"
          height="100"
          alt="Site Logo"
        />
      </div>
    </div>
  );
};

export default Loader;
