import React, { Fragment, Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import "./App.css";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Helmet } from "react-helmet";

import Navbar from "./components/navbar";

import Contacts from "./components/contacts";
import Home from "./components/home";
import PantofoleAlberghi from "./components/pantofoleAlberghi";
import Prodotti from "./components/prodotti";
import Azienda from "./components/azienda";
import Footer from "./components/footer";
import Loader from "./components/common/loader";
import CiabatteSpa from "./components/ciabatteSpa";
import NotFound from "./components/notFound";
import ProductPage from "./components/common/productPage";
import Oggettistica from "./components/oggettistica";
import CookiePolicy from "./components/cookiePolicy";
import PrivacyPolicy from "./components/privacyPolicy";
import CookieConsent from "react-cookie-consent";

const pages_url = "https://gestionelorebea.altervista.org/wp-json/wp/v2/pages";
const media_url =
  "https://gestionelorebea.altervista.org/wp-json/wp/v2/media/?per_page=100";

class App extends Component {
  state = {
    isLoaded: false,
    images_home: [],
    images_products: [],
    images_contacts: [],
    images_azienda: [],
    images_newsletter: "",
    pantofole: [],
    ciabatte: [],
    oggettistica: [],
    english: JSON.parse(localStorage.getItem("english")),
    openModal: false
  };
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.getPagesInfo();
    this.getHomeImages();
    this.getProductImages();
    this.getContactsImages();
    this.getAziendaImages();
    this.getNewsletterImages();
    this.getPantofole();
    this.getCiabatte();
    this.getOggettistica();
    localStorage.setItem(
      "english",
      JSON.parse(localStorage.getItem("english"))
    );
    this.launchNewsletter()
  }

  launchNewsletter = () => {
    let today = new Date().getDay() + 1;
    let newsLetter = JSON.parse(localStorage.getItem('newsletter'));
    let match = today === newsLetter;
    if (!match) {
      localStorage.setItem('newsletter', today);
      setTimeout(() => this.setState({ openModal: true }), 5000);
    }
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  changeLanguage = () => {
    const en = !this.state.english;
    this.setState({ english: en });
    localStorage.setItem("english", JSON.parse(en));
  };

  getPagesInfo() {
    axios.get(pages_url).then(res => {
      if (this._isMounted) {
        let pages = [];
        res.data.map(page => pages.push(page));
        this.setState({
          pages,
          isLoaded: true
        });
      }
    });
  }
  getPantofole() {
    axios
      .get(
        "https://gestionelorebea.altervista.org/wp-json/wp/v2/pantofole_alberghi/?per_page=100&_embed"
      )
      .then(res => {
        if (this._isMounted) {
          let pantofole = [];
          res.data.map(pantofola => pantofole.push(pantofola));
          this.setState({
            pantofole
          });
        }
      });
  }
  getCiabatte() {
    axios
      .get(
        "https://gestionelorebea.altervista.org/wp-json/wp/v2/ciabatte_spa/?per_page=100&_embed"
      )
      .then(res => {
        if (this._isMounted) {
          let ciabatte = [];
          res.data.map(ciabatta => ciabatte.push(ciabatta));
          this.setState({
            ciabatte
          });
        }
      });
  }
  getOggettistica() {
    axios
      .get(
        "https://gestionelorebea.altervista.org/wp-json/wp/v2/oggettistica/?per_page=100&_embed"
      )
      .then(res => {
        if (this._isMounted) {
          let oggettistica = [];
          res.data.map(item => oggettistica.push(item));
          this.setState({
            oggettistica
          });
        }
      });
  }
  getHomeImages() {
    axios.get(media_url).then(res => {
      if (this._isMounted) {
        let home = {
          banner: "",
          pantofole: "",
          ciabatte: "",
          cortesia: ""
        };
        res.data
          .filter(media =>
            media.media_details.file.includes("HOME/pantofole_per_alberghi")
          )
          .map(
            media =>
              (home.pantofole = media.media_details.sizes.full.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("HOME/ciabatte_per_spa")
          )
          .map(
            media => (home.ciabatte = media.media_details.sizes.full.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("HOME/oggettistica")
          )
          .map(
            media => (home.cortesia = media.media_details.sizes.full.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("HOME/banner_sfondo")
          )
          .map(
            media => (home.banner = media.media_details.sizes.full.source_url)
          );

        this.setState({
          images_home: home,
          isLoaded: true
        });
      }
    });
  }
  getProductImages() {
    axios.get(media_url).then(res => {
      if (this._isMounted) {
        let products = {
          banner: "",
          pantofole: "",
          ciabatte: "",
          cortesia: ""
        };
        res.data
          .filter(media =>
            media.media_details.file.includes("PRODOTTI/pantofole_per_alberghi")
          )
          .map(
            media =>
              (products.pantofole =
                media.media_details.sizes.medium_large.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("PRODOTTI/ciabatte_per_spa")
          )
          .map(
            media =>
              (products.ciabatte =
                media.media_details.sizes.medium_large.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("PRODOTTI/oggettistica")
          )
          .map(
            media =>
              (products.cortesia =
                media.media_details.sizes.medium_large.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("PRODOTTI/banner_sfondo")
          )
          .map(
            media =>
              (products.banner = media.media_details.sizes.full.source_url)
          );
        this.setState({
          images_products: products,
          isLoaded: true
        });
      }
    });
  }
  getContactsImages() {
    axios.get(media_url).then(res => {
      if (this._isMounted) {
        let contacts = {
          banner: ""
        };
        res.data
          .filter(media =>
            media.media_details.file.includes("CONTATTI/banner_sfondo")
          )
          .map(
            media =>
              (contacts.banner = media.media_details.sizes.full.source_url)
          );
        this.setState({
          images_contacts: contacts,
          isLoaded: true
        });
      }
    });
  }
  getNewsletterImages() {
    axios.get(media_url).then(res => {
      if (this._isMounted) {
        let newsletter = {
          banner: ""
        };
        res.data
          .filter(media => media.media_details.file.includes("NEWSLETTER"))
          .map(
            media =>
              (newsletter.banner = media.media_details.sizes.full.source_url)
          );
        this.setState({
          images_newsletter: newsletter.banner,
          isLoaded: true
        });
      }
    });
  }
  getAziendaImages() {
    axios.get(media_url).then(res => {
      if (this._isMounted) {
        let azienda = {
          parallax_1: "",
          parallax_2: ""
        };
        res.data
          .filter(media =>
            media.media_details.file.includes("AZIENDA/parallax_1")
          )
          .map(
            media =>
              (azienda.parallax_1 = media.media_details.sizes.full.source_url)
          );
        res.data
          .filter(media =>
            media.media_details.file.includes("AZIENDA/parallax_2")
          )
          .map(
            media =>
              (azienda.parallax_2 = media.media_details.sizes.full.source_url)
          );
        this.setState({
          images_azienda: azienda,
          isLoaded: true
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const {
      isLoaded,
      pages,
      images_home,
      images_products,
      images_contacts,
      images_azienda,
      images_newsletter,
      pantofole,
      ciabatte,
      oggettistica,
      english,
      openModal
    } = this.state;

    return (
      <Router>
        {isLoaded &&
          images_home.length !== 0 &&
          images_azienda.length !== 0 &&
          images_contacts.length !== 0 &&
          images_products.length !== 0 &&
          pantofole.length !== 0 &&
          ciabatte.length !== 0 &&
          oggettistica.length !== 0
          ? (
            <Fragment>
              <Helmet>
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <title>
                  Lorebea s.r.l. | Ciabatte, Pantofole per alberghi, Ciabatte
                  personalizzate per strutture turistiche con suola in sughero
                  anti-sudore
              </title>
                <meta
                  name="description"
                  content="Produzione e commercializzazione ciabatte personalizzate in PVC repellente all'acqua con suola anti-scivolo. Ciabatte per albergo personalizzate con suola anti-scivolo. Ciabatte per la casa per uomo e donna. Accessori per il bagno fatti in spugna o tessuti naturali."
                />
                <meta name="url" content="https://www.lorebea.cloud" />
              </Helmet>
              <Navbar en={english} changeLanguage={this.changeLanguage} />
              <Modal
                open={openModal}
                onClose={this.onCloseModal}
                closeIconSize={23}
                center
                className="newsletter_modal"
              >
                <a
                  href={
                    "https://gmail.us20.list-manage.com/subscribe/post?u=5208f487c96096b93fe083585&amp;id=47836e82b5"
                  }
                >
                  <img
                    src={images_newsletter}
                    style={{
                      width: "100%"
                    }}
                    alt="newsletter-banner"
                  />
                </a>
              </Modal>
              <div className="container" style={{ backgroundColor: "#2c2c2c" }}>
                <Switch>
                  <Route
                    path="/home"
                    render={props => (
                      <Home {...props} images={images_home} english={english} />
                    )}
                  />
                  <Route
                    path="/azienda"
                    render={props => (
                      <Azienda
                        {...props}
                        azienda={_.find(pages, ["slug", "azienda"])}
                        images={images_azienda}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    path="/contatti"
                    render={props => (
                      <Contacts
                        {...props}
                        contatti={_.find(pages, ["slug", "contatti"])}
                        images={images_contacts}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/prodotti"
                    render={props => (
                      <Prodotti
                        {...props}
                        prodotti={_.find(pages, ["slug", "prodotti"])}
                        images={images_products}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/prodotti/pantofole-alberghi"
                    render={props => (
                      <PantofoleAlberghi
                        {...props}
                        pantofole={pantofole}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/prodotti/ciabatte-spa"
                    render={props => (
                      <CiabatteSpa
                        {...props}
                        ciabatte={ciabatte}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/prodotti/oggettistica"
                    render={props => (
                      <Oggettistica
                        {...props}
                        oggettistica={oggettistica}
                        english={english}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/prodotti/:slug/:id"
                    render={props => <ProductPage {...props} english={english} />}
                  />
                  <Route path="/not-found" component={NotFound} />
                  <Redirect from="/" exact to="/home" />
                  <Route
                    exact
                    path="/admin"
                    component={() => {
                      window.location.href =
                        "https://gestionelorebea.altervista.org/wp-admin/";
                      return null;
                    }}
                  />
                  <Route path="/cookie-policy" component={CookiePolicy} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Redirect to="/not-found" />
                </Switch>
              </div>
              <Footer english={english} />
              <CookieConsent
                acceptOnScroll={false}
                location="bottom"
                buttonText="Continua"
                cookieName="cookie-consent"
                style={{ background: "#1b1b1bde" }}
                buttonStyle={{
                  color: "#000",
                  fontSize: "13px",
                  backgroundColor: "#dbdbdb",
                  borderRadius: "5px",
                  marginTop: "0"
                }}
                containerClasses="text-center justify-content-center"
                contentStyle={{ fontSize: "11pt" }}
                expires={150}
              >
                <i
                  className="fas fa-info-circle mr-2"
                  style={{ fontSize: "14pt" }}
                />
                Utilizziamo cookie proprietari e di terze parti al fine di
                migliorare i nostri servizi. Continuando la navigazione autorizzi
              il loro uso. Vedi la{" "}
                <strong>
                  <Link
                    to="/cookie-policy"
                    style={{
                      fontSize: "10pt",
                      color: "#fff"
                    }}
                  >
                    Cookie Policy
                </Link>
                </strong>
                .
            </CookieConsent>
            </Fragment>
          ) : (
            <Loader />
          )}
      </Router>
    );
  }
}

export default App;
