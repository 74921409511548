import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Contacts extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { images, english, contatti } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow" />
          <title>{contatti.acf.meta_info}</title>
          <meta
            name="description"
            content="I contatti della nostra azienda e le indicazioni stradali per trovare lo stabilimento di Massa e Cozzile."
          />
          <meta name="url" content="https://www.lorebea.cloud/contatti" />
        </Helmet>
        <div className="contacts fade-in">
          <div
            className="contacts-parallax"
            style={{ backgroundImage: `url(${images.banner})` }}
          />
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="pt-3">
                {!english ? "INFORMAZIONI" : "INFORMATIONS"}
              </h2>
              <hr style={{ backgroundColor: "#6d6d6d" }} />
              <br />
              {/* <div
                dangerouslySetInnerHTML={{ __html: contatti.content.rendered }}
              /> */}
              <div style={{ padding: "0" }}>
                <h3>{!english ? "Indirizzo" : "Address"}</h3>

                <br />
                <p>
                  <i className="fas fa-map-marker-alt mr-2" /> Via Landini Remo,
                  4d
                </p>
                <p>51010 Massa e Cozzile (PT)</p>
                <hr style={{ backgroundColor: "#6d6d6d" }} />
                <h3>{!english ? "Contatti" : "Contacts"}</h3>
                <br />
                <p>
                  <i className="fas fa-phone-alt mr-2" /> +39 0572 74621
                </p>
                <p>
                  <i className="fas fa-fax mr-2" /> fax: +39 0572 390092
                </p>
                <p>
                  <i className="fas fa-envelope mr-2" />{" "}
                  <a href="mailto:info@lorebea.it">info@lorebea.it</a>
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 google-map">
              <h3 style={{ padding: "15px 10px 6px 0" }}>
                {!english ? "DOVE SIAMO" : "HOW TO FIND US"}
              </h3>
              <hr style={{ backgroundColor: "#6d6d6d" }} />
              <div style={{ paddingTop: "30px" }}>
                <iframe
                  title="Lorebea srl"
                  style={{ width: "100%", height: "400px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.514122591333!2d10.760848614937043!3d43.86589844666954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a7d0eb4ba8be1%3A0xaebca0367a7224c8!2sLorebea+Srl!5e0!3m2!1sit!2sit!4v1566139396304!5m2!1sit!2sit"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
