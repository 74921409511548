import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "hamburgers/dist/hamburgers.css";
import Flip from "react-reveal/Flip";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

const siteLogo = require("../images/site-logo.svg");
const ita = require("../images/italy.png");
const uk = require("../images/uk.png");

class Navbar extends Component {
  state = {
    color: "",
    logo: true,
    menuOpened: true,
    isHovering: false,
    arrowUp: false
  };

  handleMenuOpen = async e => {
    if (e.detail > 2) {
      return;
    }
    const toggle = !this.state.menuOpened;
    this.setState({ menuOpened: toggle });
  };
  handleSubMenuOpen = () => {
    const toggle = !this.state.menuOpened;
    this.setState({ menuOpened: toggle });
    this.toggleHoverState();
  };
  getUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  listenScrollEvent = e => {
    if (window.innerWidth < 992 && window.scrollY > 0) {
      this.setState({ color: "#0C0C0C", arrowUp: true });
    } else if (window.scrollY > 0) {
      this.setState({ color: "#0C0C0C", logo: false, arrowUp: true });
    } else {
      this.setState({ color: "", logo: true, arrowUp: false });
    }
  };
  toggleHoverState = () => {
    const hover = this.state.isHovering;
    this.setState({ isHovering: !hover });
  };
  render() {
    const { logo, menuOpened, arrowUp } = this.state;
    const { en } = this.props;
    return (
      <React.Fragment>
        <nav
          className="fixed-top navbar navbar-expand-lg navbar-dark"
          style={{ backgroundColor: this.state.color }}
        >
          {logo ? (
            <NavLink className="navbar-brand fade-in" to="/home">
              <Flip top>
                <img
                  className="site-logo mt-2 mb-2"
                  src={siteLogo}
                  width="200"
                  height="100"
                  alt="Site Logo"
                />
              </Flip>
            </NavLink>
          ) : (
            ""
          )}
          <button
            className={`navbar-toggler hamburger hamburger--collapse ${
              menuOpened ? "" : "is-active"
            }`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.handleMenuOpen}
            onDoubleClick={this.handleMenuOpen}
          >
            <span className="hamburger-box ">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <NavLink
                  onClick={this.handleMenuOpen}
                  className="nav-link "
                  to="/home"
                >
                  HOME
                </NavLink>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <NavLink
                  onClick={this.handleMenuOpen}
                  className="nav-link "
                  to="/azienda"
                >
                  {en ? "THE COMPANY" : "AZIENDA"}
                </NavLink>
              </li>
              <li
                className="nav-item product-navbar"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                /* onMouseEnter={() => this.toggleHoverState()}
                onMouseLeave={() => this.toggleHoverState()} */
              >
                <NavLink
                  onClick={this.handleMenuOpen}
                  className="nav-link"
                  to="/prodotti"
                >
                  {en ? "PRODUCTS" : "PRODOTTI"}
                </NavLink>
                {this.state.isHovering ? (
                  <Slide left>
                    <ul id="products-list">
                      <Fade cascade right>
                        <li
                          className="text-center nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <NavLink
                            onClick={this.handleSubMenuOpen}
                            className="nav-link"
                            to="/prodotti/pantofole-alberghi"
                          >
                            {en ? "HOTEL SLIPPERS" : "PANTOFOLE PER ALBERGHI"}
                          </NavLink>
                        </li>
                        <li
                          className="text-center nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <NavLink
                            onClick={this.handleSubMenuOpen}
                            className="nav-link"
                            to="/prodotti/ciabatte-spa"
                          >
                            {en ? "SPA SLIPPERS" : "CIABATTE PER SPA"}
                          </NavLink>
                        </li>
                        <li
                          className="text-center nav-item"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          <NavLink
                            onClick={this.handleSubMenuOpen}
                            className="nav-link"
                            to="/prodotti/oggettistica"
                          >
                            {en ? "FANCY GOODS" : "OGGETTISTICA"}
                          </NavLink>
                        </li>
                      </Fade>
                    </ul>
                  </Slide>
                ) : null}
              </li>
              <ul id="products-list-mobile">
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <NavLink
                    onClick={this.handleSubMenuOpen}
                    className="nav-link"
                    to="/prodotti/pantofole-alberghi"
                  >
                    {en ? "HOTEL SLIPPERS" : "PANTOFOLE PER ALBERGHI"}
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <NavLink
                    onClick={this.handleSubMenuOpen}
                    className="nav-link"
                    to="/prodotti/ciabatte-spa"
                  >
                    {en ? "SPA SLIPPERS" : "CIABATTE PER SPA"}
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <NavLink
                    onClick={this.handleSubMenuOpen}
                    className="nav-link"
                    to="/prodotti/oggettistica"
                  >
                    {en ? "FANCY GOODS" : "OGGETTISTICA"}
                  </NavLink>
                </li>
              </ul>

              <li
                className="nav-item arrow-menu-down"
                style={{
                  marginLeft: "-17px",
                  marginTop: "4px"
                }}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <i
                  onClick={this.toggleHoverState}
                  style={{ cursor: "pointer" }}
                  className={
                    !this.state.isHovering
                      ? "nav-link fas fa-angle-down ml-2"
                      : "nav-link fas fa-angle-up ml-2"
                  }
                />
              </li>

              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <NavLink
                  onClick={this.handleMenuOpen}
                  className="nav-link "
                  to="/contatti"
                >
                  {en ? "CONTACTS" : "CONTATTI"}
                </NavLink>
              </li>
              <li
                className="nav-item lang-btn"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <div
                  className="nav-link"
                  onClick={e => {
                    this.handleMenuOpen(e);
                    this.props.changeLanguage();
                  }}
                  style={{
                    padding: "6px",
                    margin: "0",
                    cursor: "pointer"
                  }}
                >
                  {en ? (
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={ita}
                      alt="ita"
                    />
                  ) : (
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={uk}
                      alt="uk"
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        </nav>
        {arrowUp ? (
          <i
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              zIndex: "999",
              fontSize: "27pt",
              cursor: "pointer",
              color: "#cdcdcd",
              margin: "30px"
            }}
            onClick={this.getUp}
            className="fas fa-arrow-alt-circle-up"
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default Navbar;
