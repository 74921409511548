import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductLoader from "./productLoader";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from "react-responsive-modal";
import { Helmet } from "react-helmet";
import { Form, Input, Textarea } from "formsy-react-components";
import Fade from "react-reveal/Fade";

class ProductPage extends Component {
  state = {
    product: {},
    isLoaded: false,
    open: false,
    imageLoaded: false,
    showForm: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getProductInfo();
  }

  getProductInfo() {
    let slug = this.props.match.params.slug;
    let new_slug = slug.replace("-", "_");
    axios
      .request({
        url: `https://gestionelorebea.altervista.org/wp-json/wp/v2/${new_slug}/?_embed&slug=${this.props.match.params.id}&?_embed`,
        method: "get"
      })
      .then(res => {
        this.setState({ product: res.data[0], isLoaded: true });
      });
  }

  getImages(product) {
    let images = [];
    if (product.acf.image_1) {
      images.push({
        original: product.acf.image_1.url,
        thumbnail: product.acf.image_1.sizes.thumbnail
      });
    }
    if (product.acf.image_2) {
      images.push({
        original: product.acf.image_2.url,
        thumbnail: product.acf.image_2.sizes.thumbnail
      });
    }
    if (product.acf.image_3) {
      images.push({
        original: product.acf.image_3.url,
        thumbnail: product.acf.image_3.sizes.thumbnail
      });
    }
    if (product.acf.image_4) {
      images.push({
        original: product.acf.image_4.url,
        thumbnail: product.acf.image_4.sizes.thumbnail
      });
    }
    if (product.acf.image_5) {
      images.push({
        original: product.acf.image_5.url,
        thumbnail: product.acf.image_5.sizes.thumbnail
      });
    }
    return images;
  }

  displayBreadcrumb(en, name) {
    if (!en) {
      if (name === "pantofole_alberghi") return "Pantofole per Alberghi";
      if (name === "ciabatte_spa") return "Ciabatte per Spa";
      if (name === "oggettistica") return "Oggettistica";
    } else {
      if (name === "pantofole_alberghi") return "Hotel Slippers";
      if (name === "ciabatte_spa") return "Spa Slippers";
      if (name === "oggettistica") return "Fancy Goods";
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  replaceSlug(slug) {
    let new_slug = slug.replace(/_/g, "-");
    return new_slug;
  }

  imageIsLoaded = () => {
    this.setState({ imageLoaded: true });
  };

  sendEmail = (data, item) => {
    axios.post("https://protected-cove-02783.herokuapp.com/send", {
      item: item,
      name: data.name,
      company: data.company,
      email: data.email,
      phone: data.phone,
      message: data.message
    });
  };

  showForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  render() {
    const { isLoaded, product, open, imageLoaded, showForm } = this.state;
    const { english } = this.props;
    if (isLoaded) {
      let images = this.getImages(product);
      //let date = new Date(product.modified);
      let content = product.content.rendered;
      let english_content = product.acf.english_content;
      let english_title = product.acf.english_title;
      return (
        <React.Fragment>
          <Helmet>
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <title>{product.acf.meta_info}</title>
            <meta
              name="description"
              content="Produzione e commercializzazione ciabatte personalizzate in PVC repellente all'acqua con suola anti-scivolo. Ciabatte per albergo personalizzate con suola anti-scivolo. Ciabatte per la casa per uomo e donna. Accessori per il bagno fatti in spugna o tessuti naturali."
            />
            <meta
              name="url"
              content={`https://lorebea.cloud/prodotti/${this.replaceSlug(
                product.type
              )}/${product.slug}`}
            />
          </Helmet>
          <div className="pages product-page">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                  <Link to="/prodotti" className="breadcrumb-item active">
                    {!english ? "Prodotti" : "Products"}
                  </Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link
                    to={`/prodotti/${this.replaceSlug(product.type)}`}
                    className="breadcrumb-item active"
                  >
                    {this.displayBreadcrumb(english, product.type)}
                  </Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link
                    to={`/prodotti/${this.props.location.slug}/${product.slug}`}
                    onClick={e => e.preventDefault()}
                    className="breadcrumb-item"
                  >
                    {!english ? product.title.rendered : english_title}
                  </Link>
                </li>
              </ol>
            </nav>
            <div
              className="row arrow-back"
              style={{ margin: "0", padding: "20px" }}
            >
              <i
                style={{ fontSize: "20pt", cursor: "pointer" }}
                onClick={() => this.props.history.goBack()}
                className="fas fa-arrow-left mr-3"
              />
              {!english ? "Torna a" : "Back to"}{" "}
              {this.displayBreadcrumb(english, product.type)}
            </div>
            <div className="row" style={{ margin: "0", paddingBottom: "20px" }}>
              <div className="col-12 col-lg-6 slideshow">
                {images.length !== 0 ? (
                  <ImageGallery
                    items={images}
                    showFullscreenButton={
                      window.innerWidth >= 1024 ? true : false
                    }
                    lazyLoad={true}
                    autoPlay={false}
                    thumbnailPosition={
                      window.innerWidth >= 992 ? "left" : "bottom"
                    }
                    showBullets={true}
                    useBrowserFullscreen={true}
                  />
                ) : (
                  <React.Fragment>
                    <img
                      style={{ width: "100%", borderRadius: "2%" }}
                      src={
                        imageLoaded
                          ? product._embedded["wp:featuredmedia"]["0"]
                              .media_details.sizes.full.source_url
                          : product._embedded["wp:featuredmedia"]["0"]
                              .media_details.sizes.thumbnail.source_url
                      }
                      alt={product.title.rendered}
                      onLoad={this.imageIsLoaded}
                    />

                    {window.innerWidth >= 992 ? (
                      <i
                        onClick={this.onOpenModal}
                        className="fas fa-search-plus"
                        style={{
                          position: "absolute",
                          right: "40px",
                          top: "20px",
                          color: "#080808",
                          fontSize: "20pt",
                          cursor: "pointer"
                        }}
                      />
                    ) : null}

                    <Modal open={open} onClose={this.onCloseModal} center>
                      <img
                        style={{ width: "100%", padding: "0" }}
                        src={
                          product._embedded["wp:featuredmedia"]["0"]
                            .media_details.sizes.full.source_url
                        }
                        alt={product.title.rendered}
                      />
                    </Modal>
                  </React.Fragment>
                )}
              </div>
              <div
                className="col-12 col-lg-6"
                style={{ padding: "0 40px 40px 40px" }}
              >
                {!english ? (
                  <div>
                    <h2>{product.title.rendered}</h2>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                ) : (
                  <div>
                    <h2>{english_title}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: english_content }}
                    />
                  </div>
                )}
                {showForm ? (
                  <Fade cascade>
                    <div className="contact-form">
                      <h3 className="mt-3">
                        {english ? "Contact Us" : "Contattaci"}
                      </h3>
                      <p className="mt-3">
                        {english
                          ? `Contact us for an order about the item "${english_title}".`
                          : `Contattaci per effettuare un ordine relativo all'articolo "${product.title.rendered}".`}
                      </p>
                      <p>
                        {english ? (
                          <small>* required field</small>
                        ) : (
                          <small>* campo obbligatorio</small>
                        )}
                      </p>
                      <Form
                        onSubmit={data => {
                          if (data.email === undefined || data.email === "") {
                            return null;
                          }
                          this.sendEmail(data, product.title.rendered);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          this.showForm();
                        }}
                      >
                        <Input name="name" label={english ? "Name" : "Nome"} />
                        <Input
                          name="company"
                          label={english ? "Company" : "Azienda"}
                        />
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="example@gmail.com"
                          validations="isEmail"
                          validationErrors={{
                            isEmail: english
                              ? "This is not a valid address."
                              : "Questo indirizzo email non è valido."
                          }}
                          required
                        />
                        <Input
                          name="phone"
                          label={english ? "Telephone" : "Telefono"}
                          type="number"
                        />
                        <Textarea
                          rows={4}
                          cols={40}
                          name="message"
                          label={english ? "Message" : "Messaggio"}
                          placeholder={
                            english
                              ? "Ask us anything."
                              : "Cosa vuoi chiederci?"
                          }
                          help={
                            english
                              ? "Leave a message, you'll be contacted by our staff as soon as possible."
                              : "Lascia un messaggio, verrai ricontattato il prima possibile."
                          }
                        />
                        <input
                          className="btn form-send-btn"
                          formNoValidate
                          type="submit"
                          defaultValue="Submit"
                        />
                      </Form>
                    </div>
                  </Fade>
                ) : (
                  <button className="form-submit" onClick={this.showForm}>
                    {english ? "Purchase" : "Acquista"}{" "}
                    <i className="fas fa-shopping-cart" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="pages product-page text-center">
        <ProductLoader english={english} />
      </div>
    );
  }
}

export default ProductPage;
