import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

class Prodotti extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { images, english, prodotti } = this.props;
    return (
      <div className="all-prod fade-in">
        <React.Fragment>
          <Helmet>
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <title>{prodotti.acf.meta_info}</title>
            <meta
              name="description"
              content="Una ampia gamma di prodotti atti a soddisfare le esigenze del cliente senza tralasciare l'eleganza e lo stile di un prodotto made in italy."
            />
            <meta name="url" content="https://www.lorebea.cloud/prodotti" />
          </Helmet>
          {/* <h1>{prodotti.title.rendered}</h1> */}
          {/* <div
            dangerouslySetInnerHTML={{ __html: prodotti.content.rendered }}
          /> */}
          <div
            className="parallax-prod"
            style={{ backgroundImage: `url(${images.banner})` }}
          />

          <div className="row">
            <Fade left>
              <div className="col-lg-4 first">
                <Link to="/prodotti/pantofole-alberghi">
                  <div className="card prod-card ">
                    <img
                      src={images.pantofole}
                      className="card-img-top"
                      alt="..."
                    />
                    {!english ? (
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          PANTOFOLE PER ALBERGHI
                        </h5>
                        <hr />
                        <p className="card-text">
                          Pantofole per Hotel, realizzate in vari modelli e
                          tessuti, per confortare i Vostri clienti durante il
                          soggiorno.
                        </p>
                      </div>
                    ) : (
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          HOTEL SLIPPERS
                        </h5>
                        <hr />
                        <p className="card-text">
                          Hotel slippers realised in various models and
                          textiles, to comfort your clients during the stay.
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 second">
                <Link to="/prodotti/ciabatte-spa">
                  <div className="card prod-card ">
                    <img
                      src={images.ciabatte}
                      className="card-img-top"
                      alt="..."
                    />
                    {!english ? (
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          CIABATTE PER SPA
                        </h5>
                        <hr />
                        <p className="card-text">
                          Ciabatte per spa, wellness, piscine, terme ed altro,
                          realizzando i modelli più idonei per la Vostra
                          struttura.
                        </p>
                      </div>
                    ) : (
                      <div className="card-body">
                        <h5 className="card-title text-center">SPA SLIPPERS</h5>
                        <hr />
                        <p className="card-text">
                          Slippers for spa, wellness, pools and many more,
                          tought and created to be the best for your facilities.
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 third">
                <Link to="/prodotti/oggettistica">
                  <div className="card prod-card ">
                    <img
                      src={images.cortesia}
                      className="card-img-top"
                      alt="..."
                    />
                    {!english ? (
                      <div className="card-body">
                        <h5 className="card-title text-center">OGGETTISTICA</h5>
                        <hr />
                        <p className="card-text">
                          Ombrelli, grucce e guanti peeling per confortare i
                          Vostri clienti durante il soggiorno, al miglior prezzo
                          per Voi.
                        </p>
                      </div>
                    ) : (
                      <div className="card-body">
                        <h5 className="card-title text-center">FANCY GOODS</h5>
                        <hr />
                        <p className="card-text">
                          Umbrellas, hangers and peeling gloves to comfort your
                          clients during the stay, at the best price just for
                          you.
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            </Fade>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default Prodotti;
