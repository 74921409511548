import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Azienda extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { azienda, images, english } = this.props;
    let english_content = azienda.acf.english_content;
    return (
      <div>
        <React.Fragment>
          <Helmet>
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <title>{azienda.acf.meta_info}</title>
            <meta
              name="description"
              content="Distribuzione di prodotti per centri estetici, termali, wellness, beauty farm e alberghi a livello internazionale, garantendo serietà e professionalità."
            />
            <meta name="url" content="https://lorebea.cloud/azienda" />
          </Helmet>
          <div
            className="parallax fade-in"
            style={{ backgroundImage: `url(${images.parallax_1})` }}
          />
          {azienda ? (
            <div className="azienda fade-in">
              <h3
                style={{ textTransform: "uppercase" }}
                className="text-center pb-4"
              >
                {!english ? azienda.title.rendered : "The Company"}
              </h3>
              {!english ? (
                <div
                  dangerouslySetInnerHTML={{ __html: azienda.content.rendered }}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: english_content }} />
              )}
            </div>
          ) : null}
          <div
            className="parallax2 fade-in"
            style={{ backgroundImage: `url(${images.parallax_2})` }}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default Azienda;
