import React, { Component } from "react";
import { MagicSpinner } from "react-spinners-kit";
import { Link } from "react-router-dom";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <div
        className="pages text-center not-found"
        style={{ color: "#dbdbdb", padding: "100px 40px 40px 40px" }}
      >
        <h1>Page Not Found</h1>
        <p>La pagina che stai cercando non esite.</p>
        <MagicSpinner size={100} />
        <Link to="/" className="go-back">
          {"> Torna alla Home <"}
        </Link>
      </div>
    );
  }
}

export default NotFound;
