import React from "react";
import { css } from "@emotion/core";
// First way to import
import { ScaleLoader } from "react-spinners";
// Another way to import

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #fff;
`;

export default class ProductLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  render() {
    const { english } = this.props;
    return (
      <div className="sweet-loading">
        <p>{english ? "Loading..." : "Attendi..."}</p>
        <ScaleLoader
          css={override}
          sizeUnit={"px"}
          height={35}
          width={4}
          radius={2}
          margin={"2px"}
          color={"#dbdbdb"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
